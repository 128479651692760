@import 'css/estilo.min.css';

*
{
    margin: 0;
    padding: 0;
}

html
{
    background: #1e1a20;
}

/* html,
body
{
    overflow: hidden;
} */

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}


.btnList {
    position: absolute;
  }
  
  .animate {
    background-color: #4CAF55; 
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
  /*   display: inline-block; */
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin: 10px;
    cursor: pointer;
  }
  
  #camMove {
    background-color: #0000ff;
  }
  
  #camMoveTwo {
    background-color: #ff0000;
  }
  
  #camMoveThree {
    background-color: #009900;
  }
  
  #camMoveFour {
    background-color: #e67300;
  }
  
  #camMoveFive {
    background-color: #9900cc;
  }
  
  #camMoveSix {
    background-color: #ffcc00;
  }
  
  #camMoveSev {
    background-color: #99ccff;
  }